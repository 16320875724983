import * as React from "react"

const TypographySection = (props) => {
    return (
        <section className="typography-section">
            <div className="container">
                <div className="image-block-1">
                    <div className="image-block">
                        <img
                            src={props.typography?.sourceUrl}
                            width={props.typography?.width}
                            alt={props.typography?.altText}
                        />
                    </div>  
                </div>
            </div>
        </section> 
    )
}

export default TypographySection;